import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby-link'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import ContactImg from '../../static/img/Contact.png'
import ContactForm from '../components/ContactForm'

export const ContactPageTemplate = ({
  backgroundimage,
}) => (
  <div>
    <div
      className="full-width-image margin-top-0"
      style={{
        backgroundImage: `url(${!!backgroundimage.childImageSharp ? backgroundimage.childImageSharp.fluid.src : backgroundimage
          })`,
      }}
    >
      <div
        style={{
          display: 'flex',
          lineHeight: '1',
          justifyContent: 'space-around',
          alignItems: 'left',
          flexDirection: 'column',
        }}
      >
        <img src={ContactImg} alt="Title" style={{ height: '300px' }} />
      </div>
    </div>
    <section className="section">
      <div className="container">
        <div className="content">
          <ContactForm />
        </div>
      </div>
    </section>
    
  </div>
)

ContactPageTemplate.propTypes = {
  backgroundimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

const ContactPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <ContactPageTemplate
        backgroundimage={frontmatter.backgroundimage}
      />
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}


export default ContactPage

export const pageQuery = graphql`
  query ContactPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
      frontmatter {
        backgroundimage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
